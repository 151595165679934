//To use, add some varient of @import '../../../../variables'; to the top of the component's SCSS file.
$grey-background: #f5f5f5;
$btn-color-active-text:#3f94f7;
$btn-color-active-bg:#eaf4fe;

// sizes
$main-font-size: 12px;
$line-height: 25px;
$panel-dashboard-background: rgba(255, 255, 255, 0.6);
$high-opacity: 0.6;

// z-index
$table-z-index: 401;

// card
$item-spacing: 6px;
$card-padding: 10px;
