/*TODO: Consider moving these imports into the global style.scss file, especially if we have
  more than one table component, so we don't duplicate this file several times.*/
@import "../node_modules/@ag-grid-community/core/dist/styles/ag-grid.css";
@import "../node_modules/@ag-grid-community/core/dist/styles/ag-theme-alpine.css";
@import "../node_modules/leaflet/dist/leaflet.css";
@import "../node_modules/@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css";
@import "../node_modules/mapbox-gl/dist/mapbox-gl.css";
@import "../node_modules/@mapbox/mapbox-gl-geocoder/lib/mapbox-gl-geocoder.css";
@import "../node_modules/@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
@import "variables";

/* You can add global styles to this file, and also import other style files */
body {
  color: #1b1b1f;
  font-family: 'Neue Haas Grotesk Display Pro', sans-serif;
}

/*
Force icons in all dropdown menus to align on right side by giving them margin-left.
TODO: If this is too global, move it into src/app/main-page/panels/panel-main-menu/panel-main-menu.component.scss
*/
[nz-menu].ant-dropdown-menu .ant-dropdown-menu-item[nz-menu-item] i {
  margin-left: auto;
  padding-left: 5px;
}

/*All buttons inside the top-right header of collapse panels should have min-width:25px;*/
nz-collapse-panel .ant-collapse-extra i {
  min-width: 25px;
  opacity: 0.6;

  &:hover {
    opacity: 1;
  }
}

/*TODO: Is this WAY too global? If so, fix it.*/
/*Default style for icon buttons*/
button.ant-btn-icon-only {
  opacity: $high-opacity;

  &:hover {
    opacity: 1;
  }
}

.ant-divider-horizontal {
  margin: 8px 0;
}

.default-opacity {
  opacity: 0.25;
}

.dashboard-opacity {
  opacity: $high-opacity;
}

// sizes
.main-font-size {
  font-size: $main-font-size;
}

.main-height {
  height: $line-height;
}

.font-size-18 {
  font-size: 18px;
}

// badges
.yellow-badge {
  color: #ebbc00;
  background-color: #ebbc001a;
  margin: 0 15px 1px 0;
  padding: 3px 5px;
}

.purple-badge {
  color: #bd65fc;
  background-color: #bd65fc1a;
  margin: 0 15px 1px 0;
  padding: 3px 5px;
}

.blue-badge {
  color: #5956d5;
  background-color: #5956d51a;
  margin: 0 15px 1px 0;
  padding: 3px 5px;
}

.peach-badge {
  color: #AE8060;
  background-color: #5956d51a;
  margin: 0 15px 1px 0;
  padding: 3px 5px;
}

.pink-badge {
  color: #FFC0CB;
  background-color: #f89ea61f;
  margin: 0 15px 1px 0;
  padding: 3px 5px;
}

.orange-badge {
  color: #f08935;
  background-color: #f089352f;
  margin: 0 15px 1px 0;
  padding: 3px 5px;
}

.high-opacity {
  opacity: $high-opacity;
}

.label-title-wrapper {
  label {
    font-size: $main-font-size;
  }
}

.selectable-cursor {
  cursor: pointer;
}

.no-pointer-events {
  pointer-events: none;
  cursor: none;
}

.number-input-suffix-container {
  height: $line-height;
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type=number] {
    -moz-appearance: textfield;
  }

  .number-input-suffix-wrapper {

    .ant-input-number-handler-up:hover, .ant-input-number-handler-down:hover {
      color: #40a9ff;
    }

    i {
      min-width: auto;
      margin-right: 0;
      font-size: 7px;
      top: 50%;
      text-align: center;
    }

  }

  .suffix-wrapper {
    position: absolute;
    top: 0;
    right: 6px;
    font-size: 14px;
    opacity: 0.25;
    transition: opacity 0.24s linear 0.1s;
  }

  &:hover {
    .suffix-wrapper {
      opacity: 0;
    }

    .number-input-suffix-wrapper {
      border: #40a9ff 1px solid;
      border-left: hidden;
      opacity: 1;
      z-index: 1;
    }
  }
}

.div-disabled {
  cursor: none !important;
  opacity: 0.4;
}

.delete-plan-modal .ant-modal-content .ant-modal-body .ant-modal-confirm-body-wrapper .ant-modal-confirm-body .ant-modal-confirm-title {
  width: 290px;
}

.prefix-wrapper {
  position: absolute;
  left: 0;
  top: 2px;
  bottom: 0;
  padding: 0 5px;
  display: flex;
  align-items: center;
  // background-color: #f5f5f5;
  // border-right: 1px solid #d9d9d9;
  font-size: 12px;
  font-family: 'Neue Haas Grotesk Display Pro', sans-serif;
  font-weight: 400;
  letter-spacing: -0.2px;
  opacity: 0.8;
  z-index: 2;
}

.has-prefix {
  position: relative;
}

.has-prefix ::ng-deep.ant-input-number-input {
  padding-left: 40px; /* Adjust this value based on the width of your prefix */
}

.two-lines-prefix {
  height: 44px;
}

.card-economic-container {
  font-family: 'Neue Haas Grotesk Display Pro', sans-serif;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.45);
}